import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { TbBrandDatabricks } from "react-icons/tb";

export default function Contents() {
  window.scrollTo(0,0);
  const sectionName = localStorage.getItem("sectionName");
  const Navigate = useNavigate();

  const [values, setValues] = useState();

  useEffect(() => {
    // const myHeaders = new Headers;
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/${sectionName}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(result)
        let resultArray = JSON.parse(result);
        setValues(resultArray.data);
      })
      .catch((error) => console.log(error));
  },[sectionName]);

  console.log(values);

  return (
    <>
      <div className="Section-container">
        <div className="card card-body blur-box">
          <div className="text-center" style={{backgroundColor:"#b87333", padding: "10px", color: "white"}}>
            
          <h5 style={{textTransform: "capitalize"}}> <TbBrandDatabricks /> &nbsp; {sectionName} (Master)</h5>
          </div>
        </div>
        <br/>
        <div className="card card-body blur-box"> 
          
          {
            sectionName === "fertilizers" &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Fertilizer Name</th>
                    <th>Fertilizer Type</th>
                    <th>Description</th>
                    <th>Applied Crops</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.fertilizer_name}</td>
                      <td>{items.fertilizer_type}</td>
                      <td>{items.description}</td>
                      <td>{items.applied_crops}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "insecticides" || sectionName === "fungicides" || sectionName === "miticides" || sectionName === "herbicides" || sectionName === "nematicides") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Generic Name</th>
                    <th>Active Ingredient</th>
                    <th>Mode of Action</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.generic_name}</td>
                      <td>{items.active_ingredient}</td>
                      <td>{items.mode_of_action}</td>
                      
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            sectionName === "pests" &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Pest Name</th>
                    <th>Scientific Name</th>
                    <th>Description</th>
                    <th>Common Crops Affected</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.pest_name}</td>
                      <td>{items.scientific_name}</td>
                      <td>{items.description}</td>
                      <td>{items.common_crops_affected}</td>
                      
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "vegetables" || sectionName === "fruits" || sectionName === "flowers" || sectionName === "herbs" || sectionName === "exotic_crops" || sectionName === "medicinal_plants") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Crop Name</th>
                    <th>Scientific Name</th>
                    <th>Local Name - India</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.crop_name}</td>
                      <td>{items.scientific_name}</td>
                      <td>{items.local_names}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "infrastructure_tools" || sectionName === "planting_tools" || sectionName === "irrigation_tools" || sectionName === "climate_tools" || sectionName === "harvesting_tools" || sectionName === "pests_tools" || sectionName === "transport_tools" || sectionName === "storage_tools" || sectionName === "software_tools" || sectionName === "electrical_tools") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Tool Name</th>
                    <th>Tool Type</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.name}</td>
                      <td>{items.type}</td>
                      <td>{items.description}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "length" || sectionName === "area" || sectionName === "volume_liquid" || sectionName === "volume_solid" || sectionName === "mass" || sectionName === "time" || sectionName === "temperature") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Unit</th>
                    <th>System</th>
                    <th>Conversion Factor to SI</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.unit}</td>
                      <td>{items.system}</td>
                      <td>{items.conversion_factor}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "suppliers" || sectionName === "buyers") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Data Point</th>
                    <th>Data Type</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.data_point}</td>
                      <td>{items.type}</td>
                      <td>{items.description}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "growth_promoters" || sectionName === "growing_media" || sectionName === "irrigation_systems" || sectionName === "polyhouse_materials" || sectionName === "environment_controls" || sectionName === "packaging_materials" || sectionName === "consumables" || sectionName === "plumbing_materials" || sectionName === "seeds&plants") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.data}</td>
                      <td>{items.description}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          {
            (sectionName === "fungal" || sectionName === "bacterial" || sectionName === "viral" || sectionName === "parasitic" || sectionName === "oomycete" || sectionName === "other_diseases") &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Disease Name</th>
                    <th>Scientific Name</th>
                    <th>Affected Crops</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.map((items) => (
                    <tr key={items._id}>
                      <td>{items.name}</td>
                      <td>{items.scientific_name}</td>
                      <td>{items.common_crops_affected}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            const Data = items;
                            Navigate("/form", { state: Data });
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
          <button
            className="btn btn-dark"
            onClick={(e) => Navigate("/form")}
          >
            Add +
          </button>
        </div>
      </div>
    </>
  );
}
