
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import "../App.css";
import SideBar from "./Sidebar";
import { Outlet } from "react-router-dom";

export default function Layout() {
  const [collapsed, setCollapsed] = useState(false);

  // Function to toggle collapse
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className="Main-container">
        <div className="Main-body">
          <SideBar collapsed={collapsed} />

          <div className="Section-container">
            <header className="Main-header">
              <h5 className="">
                <FaBars className="menu-icon text-start" onClick={toggleSidebar} />&nbsp; &nbsp;
                <img className="text-center" style={{width: "40px", height: "40px"}} src="/demetrix_logo-white.png"/>&nbsp;
                {/* Menu icon */}
                Demetrix Infotech
                
              </h5>
            </header>
            <Outlet />
            {/* <footer className="Main-footer text-center">
              &copy; Powdered by Demetrix Infotech
            </footer> */}
          </div>
        </div>
      </div>
    </>
  );
}
