import React, { useEffect, useState } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineEditNote } from "react-icons/md";

export default function FormTemplate() {
  window.scrollTo(0, 0);
  const sectionName = localStorage.getItem("sectionName");
  const location = useLocation();
  const data = location.state;
  const Navigate = useNavigate();
  // console.log("data", data)
  // data values with different keys names for different master so multiple keys are given inventory_master (fertilizers) || pests_master || inventory_master (pesticides)
  const [field1, setField1] = useState(data?.fertilizer_name || data?.pest_name || data?.generic_name || data?.crop_name || data?.name || data?.unit || data?.data_point || data?.data);
  const [field2, setField2] = useState(data?.fertilizer_type || data?.scientific_name || data?.active_ingredient || data?.type || data?.system);
  const [field3, setField3] = useState(data?.description || data?.description || data?.mode_of_action || data?.local_names || data?.conversion_factor || data?.common_crops_affected);
  const [field4, setField4] = useState(data?.applied_crops || data?.common_crops_affected);
  const [title1 , setTitle1] = useState();
  const [title2 , setTitle2] = useState();
  const [title3 , setTitle3] = useState();
  const [title4 , setTitle4] = useState();

  useEffect(()=>{
    if(sectionName === "fertilizers"){
      setTitle1("Fertilizer Name:");
      setTitle2("Fertilizer Type:");
      setTitle3("Description:");
      setTitle4("Applied Crops:");
    } else if (sectionName === "insecticides" || sectionName === "fungicides" || sectionName === "miticides" || sectionName === "herbicides" || sectionName === "nematicides"){
      setTitle1("Generic Name:");
      setTitle2("Active Ingredient:");
      setTitle3("Mode of Action:");
    } else if (sectionName === "pests"){
      setTitle1("Pest Name:");
      setTitle2("Scientific Name:");
      setTitle3("Description:");
      setTitle4("Common Crops Affected:");
    } else if (sectionName === "vegetables" || sectionName === "fruits" || sectionName === "flowers" || sectionName === "herbs" || sectionName === "exotic_crops" || sectionName === "medicinal_plants"){
      setTitle1("Crop Name:");
      setTitle2("Scientific Name:");
      setTitle3("Local Name - India:");
    } else if (sectionName === "infrastructure_tools" || sectionName === "planting_tools" || sectionName === "irrigation_tools" || sectionName === "climate_tools" || sectionName === "harvesting_tools" || sectionName === "pests_tools" || sectionName === "transport_tools" || sectionName === "storage_tools" || sectionName === "software_tools" || sectionName === "electrical_tools"){
      setTitle1("Tool Name:");
      setTitle2("Tool Type:");
      setTitle3("Description:");
    } else if (sectionName === "length" || sectionName === "area" || sectionName === "volume_liquid" || sectionName === "volume_solid" || sectionName === "mass" || sectionName === "time" || sectionName === "temperature"){
      setTitle1("Unit:");
      setTitle2("System:");
      setTitle3("Conversion Factor to SI:");
    } else if (sectionName === "suppliers" || sectionName === "buyers"){
      setTitle1("Data Point:");
      setTitle2("Data Type:");
      setTitle3("Description:");
    } else if (sectionName === "growth_promoters" || sectionName === "growing_media" || sectionName === "irrigation_systems" || sectionName === "polyhouse_materials" || sectionName === "environment_controls" || sectionName === "packaging_materials" || sectionName === "consumables" || sectionName === "plumbing_materials" || sectionName === "seeds&plants"){
      setTitle1("Data:");
      setTitle3("Description:");
    } else if (sectionName === "fungal" || sectionName === "bacterial" || sectionName === "viral" || sectionName === "parasitic" || sectionName === "oomycete" || sectionName === "other_diseases"){
      setTitle1("Disease Name:");
      setTitle2("Scientific Name:");
      setTitle3("Affected Crops:");
    }
  },)

  const handleSubmit = (event) => {
    event.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = ""
    var URL = ""


    if (sectionName === "fertilizers"){
      raw = JSON.stringify({
        "fertilizer_name": field1,
        "fertilizer_type": field2,
        "description": field3,
        "applied_crops": field4
    })
    } 
    else if ((sectionName === "insecticides" || sectionName === "fungicides" || sectionName === "miticides" || sectionName === "herbicides" || sectionName === "nematicides")){
      raw = JSON.stringify({
        "generic_name": field1,
        "active_ingredient": field2,
        "mode_of_action": field3
      })
    } 
    else if (sectionName === "pests"){
      raw = JSON.stringify({
        "pest_name": field1,
        "scientific_name": field2,
        "description": field3,
        "common_crops_affected": field4
      })
    }
    else if ((sectionName === "vegetables" || sectionName === "fruits" || sectionName === "flowers" || sectionName === "herbs" || sectionName === "exotic_crops" || sectionName === "medicinal_plants")){
      raw = JSON.stringify({
        "crop_name": field1,
        "scientific_name": field2,
        "local_names": field3,
      })
    }
    else if ((sectionName === "infrastructure_tools" || sectionName === "planting_tools" || sectionName === "irrigation_tools" || sectionName === "climate_tools" || sectionName === "harvesting_tools" || sectionName === "pests_tools" || sectionName === "transport_tools" || sectionName === "storage_tools" || sectionName === "software_tools" || sectionName === "electrical_tools")){
      raw = JSON.stringify({
        "name": field1,
        "type": field2,
        "description": field3,
      })
    }
    else if ((sectionName === "length" || sectionName === "area" || sectionName === "volume_liquid" || sectionName === "volume_solid" || sectionName === "mass" || sectionName === "time" || sectionName === "temperature")){
      raw = JSON.stringify({
        "unit": field1,
        "system": field2,
        "conversion_factor": field3,
      })
    }
    else if ((sectionName === "suppliers" || sectionName === "buyers")){
      raw = JSON.stringify({
        "data_point": field1,
        "type": field2,
        "description": field3,
      })
    }
    else if ((sectionName === "growth_promoters" || sectionName === "growing_media" || sectionName === "irrigation_systems" || sectionName === "polyhouse_materials" || sectionName === "environment_controls" || sectionName === "packaging_materials" || sectionName === "consumables" || sectionName === "plumbing_materials"|| sectionName === "seeds&plants")){
      raw = JSON.stringify({
        "data": field1,
        "description": field3,
      })
    }
    else if (sectionName === "fungal" || sectionName === "bacterial" || sectionName === "viral" || sectionName === "parasitic" || sectionName === "oomycete" || sectionName === "other_diseases"){
      raw = JSON.stringify({
        "name": field1,
        "scientific_name": field2,
        "common_crops_affected": field3,
      })
    }
    
    if(data){
      URL = `${process.env.REACT_APP_BASE_URL}/edit_${sectionName}/${data?._id}`
    }
    else{
        URL = `${process.env.REACT_APP_BASE_URL}/add_${sectionName}`
    }
    
    const requestOptions = {
        method: (data?'PUT':'POST'),
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    }
    
    fetch(URL, requestOptions)
    .then((response)=>response.text())
    .then((result)=>{
        // console.log(result);
        let  resultArray = JSON.parse(result);
        if(resultArray.status===201 || resultArray.status===200){
            alert(resultArray.message)
            Navigate(`/${sectionName}`)
        } else {
            alert("Item not added")
        }
    })
    .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="Section-container">
        <div className="card card-body blur-box">
          <div className="" style={{backgroundColor:"#b87333", padding: "10px", color: "white"}}> 
          <h5 style={{textTransform: "capitalize"}}> <MdOutlineEditNote size={30}/>  {sectionName} data - {data?("Edit "):("Add New ")}</h5>
          </div>
        </div>
        <br/>
        <div className="card card-body blur-box">
          <form onSubmit={handleSubmit}>
            {
              title1?(
                <div className="mb-4 align-middle row">
              <label className="form-content col-2 text-center">{title1}</label>
              <input className="form-content col" value={field1} onChange={(e) => setField1(e.target.value)} required/>
            </div>
              ):(<div></div>)
            }
            {
              title2?(
                <div className="mb-4 align-middle row">
                <label className="form-content col-2 text-center">{title2}</label>
                <input className="form-content col" value={field2} onChange={(e)=>setField2(e.target.value)}/>
            </div>
              ):(<div></div>)
            }
            
            {
              title3?(
              <div className="mb-4 align-middle row">
                <label className="form-content col-2 text-center">{title3}</label>
                <textarea className="form-content col" rows={5} value={field3} onChange={(e)=>setField3(e.target.value)}/>
            </div>):(<div></div>)
            }
            
            {
              title4?(
                <div className="mb-4 align-middle row row">
                <label className="form-content col-2 text-center">{title4}</label>
                <textarea className="form-content col" rows={5} value={field4} onChange={(e)=>setField4(e.target.value)}/>
            </div>
              ):(<div></div>)
            }
            
            <div className="form-content">
              <button className="btn btn-dark">Submit</button> &nbsp;&nbsp;
              <button className="btn btn-dark" onClick={(e)=>{Navigate(`/${sectionName}`)}}>Close</button>
            </div>
            
          </form>
        </div>
      </div>
    </>
  );
}
