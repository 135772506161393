import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import FormTemplate from "./components/FormTemplate";
import Layout from "./components/Layout";
import Contents from "./pages/Contents";
import EmptyTemplate from "./pages/EmptyTemplate";

export default function App(){
  const sectionName = localStorage.getItem("sectionName")
  return(
    <>
    <BrowserRouter>
    
    <Routes>
      <Route element={<Layout /> }>
        <Route path="/form" element={<FormTemplate/>}/>
        <Route path="/fertilizers" element={<Contents/>}/>
        <Route path="/insecticides" element={<Contents/>}/>
        <Route path="/fungicides" element={<Contents/>}/>
        <Route path="/herbicides" element={<Contents/>}/>
        <Route path="/miticides" element={<Contents/>}/>
        <Route path="/nematicides" element={<Contents/>}/>
        <Route path="/pests" element={<Contents/>}/>
        <Route path="/vegetables" element={<Contents/>}/>
        <Route path="/fruits" element={<Contents/>}/>
        <Route path="/flowers" element={<Contents/>}/>
        <Route path="/herbs" element={<Contents/>}/>
        <Route path="/exotic_crops" element={<Contents/>}/>
        <Route path="/medicinal_plants" element={<Contents/>}/>
        <Route path="/infrastructure_tools" element={<Contents/>}/>
        <Route path="/planting_tools" element={<Contents/>}/>
        <Route path="/irrigation_tools" element={<Contents/>}/>
        <Route path="/climate_tools" element={<Contents/>}/>
        <Route path="/harvesting_tools" element={<Contents/>}/>
        <Route path="/pests_tools" element={<Contents/>}/>
        <Route path="/transport_tools" element={<Contents/>}/>
        <Route path="/storage_tools" element={<Contents/>}/>
        <Route path="/software_tools" element={<Contents/>}/>
        <Route path="/electrical_tools" element={<Contents/>}/>
        <Route path="/length" element={<Contents/>}/>
        <Route path="/area" element={<Contents/>}/>
        <Route path="/volume_liquid" element={<Contents/>}/>
        <Route path="/volume_solid" element={<Contents/>}/>
        <Route path="/mass" element={<Contents/>}/>
        <Route path="/time" element={<Contents/>}/>
        <Route path="/temperature" element={<Contents/>}/>
        <Route path="/suppliers" element={<Contents/>}/>
        <Route path="/buyers" element={<Contents/>}/>
        <Route path="/growth_promoters" element={<Contents/>}/>
        <Route path="/growing_media" element={<Contents/>}/>
        <Route path="/irrigation_systems" element={<Contents/>}/>
        <Route path="/polyhouse_materials" element={<Contents/>}/>
        <Route path="/environment_controls" element={<Contents/>}/>
        <Route path="/packaging_materials" element={<Contents/>}/>
        <Route path="/consumables" element={<Contents/>}/>
        <Route path="/plumbing_materials" element={<Contents/>}/>
        <Route path="/fungal" element={<Contents/>}/>
        <Route path="/bacterial" element={<Contents/>}/>
        <Route path="/viral" element={<Contents/>}/>
        <Route path="/parasitic" element={<Contents/>}/>
        <Route path="/oomycete" element={<Contents/>}/>
        <Route path="/other_diseases" element={<Contents/>}/>
        <Route path="/seeds&plants" element={<Contents/>}/>
        <Route path="/access" element={<EmptyTemplate/>}/>
        <Route path="/" element={<EmptyTemplate/>}/>

        <Route path="/labour_employee" element={<EmptyTemplate/>}/>
        <Route path="/labour_wage" element={<EmptyTemplate/>}/>
        <Route path="/labour_shift" element={<EmptyTemplate/>}/>
        <Route path="/labour_job" element={<EmptyTemplate/>}/>
        <Route path="/labour_leave" element={<EmptyTemplate/>}/>
        <Route path="/labour_location" element={<EmptyTemplate/>}/>
        <Route path="/labour_contractor" element={<EmptyTemplate/>}/>
        <Route path="/labour_skill" element={<EmptyTemplate/>}/>
        <Route path="/labour_holiday" element={<EmptyTemplate/>}/>
        <Route path="/labour_training" element={<EmptyTemplate/>}/>
        <Route path="/labour_grievance" element={<EmptyTemplate/>}/>
        <Route path="/labour_union" element={<EmptyTemplate/>}/>
        <Route path="/labour_tools" element={<EmptyTemplate/>}/>

        <Route path="/nursery_seed" element={<EmptyTemplate/>}/>
        <Route path="/nursery_seedling" element={<EmptyTemplate/>}/>
        <Route path="/nursery_bed" element={<EmptyTemplate/>}/>
        <Route path="/nursery_environment" element={<EmptyTemplate/>}/>
        <Route path="/nursery_fertilizer" element={<EmptyTemplate/>}/>
        <Route path="/nursery_pest" element={<EmptyTemplate/>}/>
        <Route path="/nursery_transplantation" element={<EmptyTemplate/>}/>
        <Route path="/nursery_crop" element={<EmptyTemplate/>}/>
        <Route path="/nursery_irrigation" element={<EmptyTemplate/>}/>
        <Route path="/nursery_labour" element={<EmptyTemplate/>}/>

        <Route path="/finance_coa" element={<EmptyTemplate/>}/>
        <Route path="/finance_customer" element={<EmptyTemplate/>}/>
        <Route path="/finance_vendor" element={<EmptyTemplate/>}/>
        <Route path="/finance_employee" element={<EmptyTemplate/>}/>
        <Route path="/finance_asset" element={<EmptyTemplate/>}/>
        <Route path="/finance_cost" element={<EmptyTemplate/>}/>
        <Route path="/finance_product" element={<EmptyTemplate/>}/>
        <Route path="/finance_tax" element={<EmptyTemplate/>}/>
        <Route path="/finance_bank" element={<EmptyTemplate/>}/>
        <Route path="/finance_liability" element={<EmptyTemplate/>}/>
        <Route path="/finance_payment" element={<EmptyTemplate/>}/>
        <Route path="/finance_inventory" element={<EmptyTemplate/>}/>
        <Route path="/finance_project" element={<EmptyTemplate/>}/>
        <Route path="/finance_loan" element={<EmptyTemplate/>}/>
        <Route path="/finance_currency" element={<EmptyTemplate/>}/>
        <Route path="/finance_expense" element={<EmptyTemplate/>}/>
        <Route path="/finance_budget" element={<EmptyTemplate/>}/>
        <Route path="/finance_loanInterest" element={<EmptyTemplate/>}/>

        <Route path="/sales_customer" element={<EmptyTemplate/>}/>
        <Route path="/sales_product" element={<EmptyTemplate/>}/>
        <Route path="/sales_order" element={<EmptyTemplate/>}/>
        <Route path="/sales_distribution" element={<EmptyTemplate/>}/>
        <Route path="/sales_warehouse" element={<EmptyTemplate/>}/>
        <Route path="/sales_agent" element={<EmptyTemplate/>}/>
        <Route path="/sales_price" element={<EmptyTemplate/>}/>
        <Route path="/sales_shipping" element={<EmptyTemplate/>}/>
        <Route path="/sales_discount" element={<EmptyTemplate/>}/>
        <Route path="/sales_territory" element={<EmptyTemplate/>}/>
        <Route path="/sales_return" element={<EmptyTemplate/>}/>
        <Route path="/sales_contract" element={<EmptyTemplate/>}/>
        <Route path="/sales_carrier" element={<EmptyTemplate/>}/>
        <Route path="/sales_invoice" element={<EmptyTemplate/>}/>
        <Route path="/sales_payment" element={<EmptyTemplate/>}/>
        <Route path="/sales_freight" element={<EmptyTemplate/>}/>
        <Route path="/sales_lead" element={<EmptyTemplate/>}/>
        <Route path="/sales_commission" element={<EmptyTemplate/>}/>

        <Route path="/climate_control" element={<EmptyTemplate/>}/>
        <Route path="/irrigation" element={<EmptyTemplate/>}/>
        <Route path="/fertigation" element={<EmptyTemplate/>}/>
        <Route path="/analytics" element={<EmptyTemplate/>}/>
      </Route>
    </Routes>
    </BrowserRouter>
    </>
  )
}