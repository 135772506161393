import React from "react";

export default function EmptyTemplate(){
    const sectionName = localStorage.getItem("sectionName");
    return(
        <>
        <div className="Section-container">
            <div className="card card-body blur-box">
            <h5 style={{textTransform: "capitalize"}}>{sectionName} (Master)</h5>
            </div>
            
        </div>
        </>
    )
}