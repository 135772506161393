
import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaList, FaUser, FaTachometerAlt, FaBars, FaChartLine, FaUsersCog, FaUserTie   } from 'react-icons/fa';
import { MdOutlineDashboard, MdPointOfSale, MdOutlinePestControl, MdOutlineInventory } from "react-icons/md";
import { GiPlantsAndAnimals, GiMoneyStack, GiWateringCan, GiPlantWatering } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";
import { PiPottedPlant, PiPlant } from "react-icons/pi";
import { WiDayRainMix } from "react-icons/wi";
import { TbRulerMeasure } from "react-icons/tb";
import { FaPeopleGroup, FaPeopleLine  } from "react-icons/fa6";

import '../App.css'
import { NavLink, useNavigate } from 'react-router-dom';

export default function SideBar({collapsed}) {
  const Navigate  = useNavigate();
  return (
  <>
      <Sidebar collapsed={collapsed}>
        <Menu style={{marginTop: "100px"}}>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "dashboard")}}>
            <MenuItem icon={<MdOutlineDashboard size={20}/>} >Dashboard</MenuItem>
          </NavLink>

          <NavLink to="/access" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "access")}}>
            <MenuItem label="Access Control" icon={<FaUsersCog size={20} />}>Access Control</MenuItem>
          </NavLink>

          <NavLink to="/pests" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "pests")}}>
            <MenuItem label="Pests MGMT" icon={<MdOutlinePestControl size={25}/>} >Pests MGMT</MenuItem>
          </NavLink>
          
          <SubMenu label="Disease MGMT" icon={<GiPlantsAndAnimals size={20}/>}>
            <NavLink to="/fungal" onClick={(e)=>{localStorage.setItem("sectionName", "fungal");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Fungal Diseases</MenuItem>
            </NavLink>
            <NavLink to="/bacterial" onClick={(e)=>{localStorage.setItem("sectionName", "bacterial");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Bacterial Diseases</MenuItem>
            </NavLink>
            <NavLink to="/viral" onClick={(e)=>{localStorage.setItem("sectionName", "viral");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Viral Diseases</MenuItem>
            </NavLink>
            <NavLink to="/parasitic" onClick={(e)=>{localStorage.setItem("sectionName", "parasitic");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Parasitic Diseases</MenuItem>
            </NavLink>
            <NavLink to="/oomycete" onClick={(e)=>{localStorage.setItem("sectionName", "oomycete");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Oomycete Diseases</MenuItem>
            </NavLink>
            <NavLink to="/other_diseases" onClick={(e)=>{localStorage.setItem("sectionName", "other_diseases");}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Other Diseases</MenuItem>
            </NavLink>
          </SubMenu>

          <SubMenu label="Labour MGMT" icon={<GrUserWorker size={20} />}>
            <NavLink to ="/labour_employee" onClick={(e)=>{localStorage.setItem("sectionName", "labour_employee")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Employee/Labor Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_wage" onClick={(e)=>{localStorage.setItem("sectionName", "labour_wage")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Wage Structure Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_shift" onClick={(e)=>{localStorage.setItem("sectionName", "labour_shift")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Shift Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_job" onClick={(e)=>{localStorage.setItem("sectionName", "labour_job")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Job Role/Task Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_leave" onClick={(e)=>{localStorage.setItem("sectionName", "labour_leave")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Leave/Absence Policy Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_location" onClick={(e)=>{localStorage.setItem("sectionName", "labour_location")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Work Location Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_contractor" onClick={(e)=>{localStorage.setItem("sectionName", "labour_contractor")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Contractor Master (for Contract Labor)</MenuItem>
            </NavLink>
            <NavLink to ="/labour_skill" onClick={(e)=>{localStorage.setItem("sectionName", "labour_skill")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Skill Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_holiday" onClick={(e)=>{localStorage.setItem("sectionName", "labour_holiday")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Holiday Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_training" onClick={(e)=>{localStorage.setItem("sectionName", "labour_training")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Training Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_grievance" onClick={(e)=>{localStorage.setItem("sectionName", "labour_grievance")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Grievance Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_union" onClick={(e)=>{localStorage.setItem("sectionName", "labour_union")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Union/Association Master</MenuItem>
            </NavLink>
            <NavLink to ="/labour_tools" onClick={(e)=>{localStorage.setItem("sectionName", "labour_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Equipment/Tool Master</MenuItem>
            </NavLink>
          </SubMenu>

          <SubMenu label="Nursery MGMT" icon={<PiPottedPlant size={25} />}>
            <NavLink to ="/nursery_seed" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_seed")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Seed Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_seedling" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_seedling")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Seedling Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_bed" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_bed")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Nursery Bed Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_environment" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_environment")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Nursery Environment Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_fertilizer" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_fertilizer")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Fertilizer/Nutrient Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_pest" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_pest")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Pest/Disease Prevention Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_transplantation" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_transplantation")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Transplantation Protocol Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_crop" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_crop")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Crop Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_irrigation" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_irrigation")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Irrigation Master</MenuItem>
            </NavLink>
            <NavLink to ="/nursery_labour" onClick={(e)=>{localStorage.setItem("sectionName", "nursery_labour")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Labor Master</MenuItem>
            </NavLink>
          </SubMenu>

          <SubMenu label="Inventory MGMT" icon={<MdOutlineInventory size={20} />}>
            <NavLink to ="/seeds&plants" onClick={(e)=>{localStorage.setItem("sectionName", "seeds&plants")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Seeds & Plants</MenuItem>
            </NavLink>
            <NavLink to ="/fertilizers" onClick={(e)=>{localStorage.setItem("sectionName", "fertilizers")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Fertilizers</MenuItem>
            </NavLink>

            <SubMenu label="Pesticides" icon={<FaList />}>
              <NavLink to ="/insecticides" onClick={(e)=>{localStorage.setItem("sectionName", "insecticides")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Insecticides</MenuItem>
              </NavLink>
              <NavLink to ="/fungicides" onClick={(e)=>{localStorage.setItem("sectionName", "fungicides")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Fungicides</MenuItem>
              </NavLink>
              <NavLink to ="/miticides" onClick={(e)=>{localStorage.setItem("sectionName", "miticides")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Miticides (Acaricides)</MenuItem>
              </NavLink>
              <NavLink to ="/herbicides" onClick={(e)=>{localStorage.setItem("sectionName", "herbicides")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Herbicides</MenuItem>
              </NavLink>
              <NavLink to ="/nematicides" onClick={(e)=>{localStorage.setItem("sectionName", "nematicides")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Nematicides</MenuItem>
              </NavLink>
            </SubMenu>
            
            <NavLink to ="/growth_promoters" onClick={(e)=>{localStorage.setItem("sectionName", "growth_promoters")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Plant Growth Promoters</MenuItem>
            </NavLink>
            <NavLink to ="/growing_media" onClick={(e)=>{localStorage.setItem("sectionName", "growing_media")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Soil & growing media</MenuItem>
            </NavLink>
            <NavLink to ="/irrigation_systems" onClick={(e)=>{localStorage.setItem("sectionName", "irrigation_systems")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Irrigation system & accessories</MenuItem>
            </NavLink>
            <NavLink to ="/polyhouse_materials" onClick={(e)=>{localStorage.setItem("sectionName", "polyhouse_materials")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Poly house structure & materials</MenuItem>
            </NavLink>

            <SubMenu label="Tools & equipments" icon={<FaList />}>
              <NavLink to ="/infrastructure_tools" onClick={(e)=>{localStorage.setItem("sectionName", "infrastructure_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Infrastructure Tools & Equipment</MenuItem>
              </NavLink>
              <NavLink to ="/planting_tools" onClick={(e)=>{localStorage.setItem("sectionName", "planting_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Planting & Maintenance Tools</MenuItem>
              </NavLink>
              <NavLink to ="/irrigation_tools" onClick={(e)=>{localStorage.setItem("sectionName", "irrigation_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Irrigation System Tools</MenuItem>
              </NavLink>
              <NavLink to ="/climate_tools" onClick={(e)=>{localStorage.setItem("sectionName", "climate_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Climate Control Equipment</MenuItem>
              </NavLink>
              <NavLink to ="/harvesting_tools" onClick={(e)=>{localStorage.setItem("sectionName", "harvesting_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Harvesting & Post-Harvest Tools</MenuItem>
              </NavLink>
              <NavLink to ="/pests_tools" onClick={(e)=>{localStorage.setItem("sectionName", "pests_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Pest and Disease MGMT Tools</MenuItem>
              </NavLink>
              <NavLink to ="/transport_tools" onClick={(e)=>{localStorage.setItem("sectionName", "transport_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Vehicles and Transport Equipment</MenuItem>
              </NavLink>
              <NavLink to ="/storage_tools" onClick={(e)=>{localStorage.setItem("sectionName", "storage_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Storage & Handling Equipment</MenuItem>
              </NavLink>
              <NavLink to ="/software_tools" onClick={(e)=>{localStorage.setItem("sectionName", "software_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Software & Monitoring Systems</MenuItem>
              </NavLink>
              <NavLink to ="/electrical_tools" onClick={(e)=>{localStorage.setItem("sectionName", "electrical_tools")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
                <MenuItem icon={<FaList />}>Power Supply and Electrical Equipment</MenuItem>
              </NavLink>
            </SubMenu>

            <NavLink to ="/environment_controls" onClick={(e)=>{localStorage.setItem("sectionName", "environment_controls")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Environment Control systems</MenuItem>
            </NavLink>
            <NavLink to ="/packaging_materials" onClick={(e)=>{localStorage.setItem("sectionName", "packaging_materials")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Packing & Storage materials</MenuItem>
            </NavLink>
            <NavLink to ="/consumables" onClick={(e)=>{localStorage.setItem("sectionName", "consumables")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Consumables & Miscellaneous</MenuItem>
            </NavLink>
            <NavLink to ="/plumbing_materials" onClick={(e)=>{localStorage.setItem("sectionName", "plumbing_materials")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Plumbing & Electrical materials</MenuItem>
            </NavLink>
          </SubMenu>

          <SubMenu label="Financial MGMT" icon={<GiMoneyStack size={25} />}>
            <NavLink to ="/finance_coa" onClick={(e)=>{localStorage.setItem("sectionName", "finance_coa")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Chart of Accounts (COA)</MenuItem>
            </NavLink>
            <NavLink to ="/finance_customer" onClick={(e)=>{localStorage.setItem("sectionName", "finance_customer")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Customer Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_vendor" onClick={(e)=>{localStorage.setItem("sectionName", "finance_vendor")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Vendor/Supplier Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_employee" onClick={(e)=>{localStorage.setItem("sectionName", "finance_employee")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Employee Master (for Payroll and HR)</MenuItem>
            </NavLink>
            <NavLink to ="/finance_asset" onClick={(e)=>{localStorage.setItem("sectionName", "finance_asset")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Asset Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_cost" onClick={(e)=>{localStorage.setItem("sectionName", "finance_cost")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Cost Center Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_product" onClick={(e)=>{localStorage.setItem("sectionName", "finance_product")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Product/Crop Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_tax" onClick={(e)=>{localStorage.setItem("sectionName", "finance_tax")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Tax Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_bank" onClick={(e)=>{localStorage.setItem("sectionName", "finance_bank")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Bank Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_liability" onClick={(e)=>{localStorage.setItem("sectionName", "finance_liability")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Liability & Loan Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_payment" onClick={(e)=>{localStorage.setItem("sectionName", "finance_payment")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Payment Terms Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_inventory" onClick={(e)=>{localStorage.setItem("sectionName", "finance_inventory")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Inventory Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_project" onClick={(e)=>{localStorage.setItem("sectionName", "finance_project")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Project Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_loan" onClick={(e)=>{localStorage.setItem("sectionName", "finance_loan")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Loan and Grant Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_currency" onClick={(e)=>{localStorage.setItem("sectionName", "finance_currency")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Currency and Exchange Rate Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_expense" onClick={(e)=>{localStorage.setItem("sectionName", "finance_expense")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Expense Category Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_budget" onClick={(e)=>{localStorage.setItem("sectionName", "finance_budget")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Budget Master</MenuItem>
            </NavLink>
            <NavLink to ="/finance_loanInterest" onClick={(e)=>{localStorage.setItem("sectionName", "finance_loanInterest")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Loan Interest Master</MenuItem>
            </NavLink>
          </SubMenu>

          <SubMenu label="Sales & Distribution MGMT" icon={<MdPointOfSale size={20} />}>
            <NavLink to ="/sales_customer" onClick={(e)=>{localStorage.setItem("sectionName", "sales_customer")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Customer Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_product" onClick={(e)=>{localStorage.setItem("sectionName", "sales_product")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Product/Crop Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_order" onClick={(e)=>{localStorage.setItem("sectionName", "sales_order")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Sales Order Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_distribution" onClick={(e)=>{localStorage.setItem("sectionName", "sales_distribution")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Distribution Channel Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_warehouse" onClick={(e)=>{localStorage.setItem("sectionName", "sales_warehouse")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Warehouse/Storage Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_agent" onClick={(e)=>{localStorage.setItem("sectionName", "sales_representative")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Agent/Sales Representative Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_price" onClick={(e)=>{localStorage.setItem("sectionName", "sales_price")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Price Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_shipping" onClick={(e)=>{localStorage.setItem("sectionName", "sales_shipping")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Shipping/Logistics Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_discount" onClick={(e)=>{localStorage.setItem("sectionName", "sales_discount")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Discount/Promotions Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_territory" onClick={(e)=>{localStorage.setItem("sectionName", "sales_territory")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Sales Territory Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_return" onClick={(e)=>{localStorage.setItem("sectionName", "sales_return")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Return/Refund Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_contract" onClick={(e)=>{localStorage.setItem("sectionName", "sales_contract")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Sales Contract Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_carrier" onClick={(e)=>{localStorage.setItem("sectionName", "sales_carrier")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Carrier/Transporter Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_invoice" onClick={(e)=>{localStorage.setItem("sectionName", "sales_invoice")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Sales Invoice Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_payment" onClick={(e)=>{localStorage.setItem("sectionName", "sales_payment")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Payment Terms Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_freight" onClick={(e)=>{localStorage.setItem("sectionName", "sales_freight")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Freight/Delivery Charges Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_lead" onClick={(e)=>{localStorage.setItem("sectionName", "sales_lead")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Lead/Prospect Master</MenuItem>
            </NavLink>
            <NavLink to ="/sales_commission" onClick={(e)=>{localStorage.setItem("sectionName", "sales_commission")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Commission/Bonus Master</MenuItem>
            </NavLink>
          </SubMenu>

          <NavLink to="/climate_control" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "climate_control")}}>
            <MenuItem icon={<WiDayRainMix size={25} />} >Climate Control MGMT</MenuItem>
          </NavLink>

          <SubMenu label="Crop MGMT" icon={<PiPlant size={25}/>}>
            <NavLink to="/vegetables" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "vegetables")}}>
              <MenuItem icon={<FaList />} >Vegetables</MenuItem>
            </NavLink>
            <NavLink to="/fruits" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "fruits")}}>
              <MenuItem icon={<FaList />} >Fruits</MenuItem>
            </NavLink>
            <NavLink to="/flowers" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "flowers")}}>
              <MenuItem icon={<FaList />} >Flowers</MenuItem>
            </NavLink>
            <NavLink to="/herbs" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "herbs")}}>
              <MenuItem icon={<FaList />} >Herbs</MenuItem>
            </NavLink>
            <NavLink to="/exotic_crops" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "exotic_crops")}}>
              <MenuItem icon={<FaList />} >Exotic Crops</MenuItem>
            </NavLink>
            <NavLink to="/medicinal_plants" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "medicinal_plants")}}>
              <MenuItem icon={<FaList />} >Medicinal Crops</MenuItem>
            </NavLink>
          </SubMenu>

          <NavLink to="/irrigation" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "irrigation")}}>
            <MenuItem icon={<GiWateringCan size={25} />} >Irrigation MGMT</MenuItem>
          </NavLink>

          <NavLink to="/fertigation" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "fertigation")}}>
            <MenuItem icon={<GiPlantWatering size={25} />} >Fertigation MGMT</MenuItem>
          </NavLink>

          <NavLink to="/analytics" className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')} onClick={(e)=>{localStorage.setItem("sectionName", "analytics")}}>
            <MenuItem icon={<FaChartLine size={18}/>} >Analytics & Reporting</MenuItem>
          </NavLink>

          <SubMenu label="Unit of Measurement" icon={<TbRulerMeasure size={20} />}>
          <NavLink to ="/length" onClick={(e)=>{localStorage.setItem("sectionName", "length")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Length</MenuItem>
            </NavLink>
            <NavLink to ="/area" onClick={(e)=>{localStorage.setItem("sectionName", "area")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Area</MenuItem>
            </NavLink>
            <NavLink to ="/volume_liquid" onClick={(e)=>{localStorage.setItem("sectionName", "volume_liquid")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Volume - Liquid</MenuItem>
            </NavLink>
            <NavLink to ="/volume_solid" onClick={(e)=>{localStorage.setItem("sectionName", "volume_solid")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Volume - Solid</MenuItem>
            </NavLink>
            <NavLink to ="/mass" onClick={(e)=>{localStorage.setItem("sectionName", "mass")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Mass</MenuItem>
            </NavLink>
            <NavLink to ="/time" onClick={(e)=>{localStorage.setItem("sectionName", "time")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Time</MenuItem>
            </NavLink>
            <NavLink to ="/temperature" onClick={(e)=>{localStorage.setItem("sectionName", "temperature")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaList />}>Temperature</MenuItem>
            </NavLink>
          </SubMenu>

          <NavLink to ="/suppliers" onClick={(e)=>{localStorage.setItem("sectionName", "suppliers")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
              <MenuItem icon={<FaPeopleGroup size={20} />}>Suppliers MGMT</MenuItem>
          </NavLink>

          <NavLink to ="/buyers" onClick={(e)=>{localStorage.setItem("sectionName", "buyers")}} className={({ isActive }) => (isActive ? 'menu-link ps-menuitem-active' : 'menu-link')}>
            <MenuItem icon={<FaPeopleLine size={25} />}>Buyers MGMT</MenuItem>
          </NavLink>
        </Menu>
      </Sidebar>
    </>
  );
};
